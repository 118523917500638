import _ from 'lodash';
import Model from './lib/Model';

class Manager extends Model {

    constructor(raw) {
        super(raw);
        this.name = raw.name;
        this.agent = raw.agent;
        this.email = raw.email;
        this.tel = raw.tel;
        this.businessNumber = raw.businessNumber;
        this.ownerName = raw.ownerName;
        this.postalCode = raw.postalCode;
        this.address1 = raw.address1;
        this.address2 = raw.address2;
        this.deliveryRequirement = raw.deliveryRequirement ?? '';
        this.earlyDelivery = raw.earlyDelivery ?? '';
        this.accountBank = raw.accountBank;
        this.accountNumber = raw.accountNumber;
        this.accountHolder = raw.accountHolder;
        this.isGranted = raw.isGranted;
    }
}

export default Manager;
