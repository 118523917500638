import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseLayout from 'layouts/CloseLayout';
import Link from 'components/Link';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        marginTop: theme.spacing(-8)
    },
    img: {
        width: 100,
        margin: theme.spacing(2, 0)
    },
    text: {
        fontWeight: 'bold'
    }

}));

const ErrorPage = () => {
    const classes = useStyles();
    const handleRefresh = () => {
        if (window?.location?.reload) {
            window.location.reload();
        }
    };
    return (
        <CloseLayout>
            <Box className={classes.container}>
                <img className={classes.img} src={'404.png'}/>
                <Typography className={classes.text} variant={'body1'} color={'primary'} align={'center'}>
                    알수 없는 페이지 에러가 발생했습니다.
                </Typography>
                <Button
                    onClick={handleRefresh}
                >
                    새로고침하기
                </Button>
                <Link
                    to={'/'}
                >
                    홈으로 가기
                </Link>
            </Box>
        </CloseLayout>
    );
};

export default ErrorPage;
