import React from 'react';
import {Redirect, Route, useLocation} from 'react-router';

import useAuth from 'hooks/useAuth';
import ErrorBoundary from './ErrorBoundary';
import ScrollToTop from './ScrollToTop';

import ErrorPage from 'pages/error';


const PrivateRoute = ({layout: Layout, component: Component, path, guard, ...props}) => {
    const {manager, branch, user, temp, logout} = useAuth();
    const {pathname} = useLocation();

    if (guard) {
        if (!user && pathname !== '/login')
            return <Redirect to={'/login'}/>;
    }

    const renderComponent = () => (
        <ScrollToTop>
            <Layout {...props}>
                <Component/>
            </Layout>
        </ScrollToTop>
    );
    return (
        <ErrorBoundary error={<ErrorPage/>}>
            <Route
                exact
                path={path}
                render={renderComponent}
            />
        </ErrorBoundary>
    );
};


export default PrivateRoute;

PrivateRoute.defaultProps = {
    guard: true
};