import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import User from 'models/User';
import Manager from 'models/Manager';
import Branch from 'models/Branch';
import Load from 'pages/load';
import axios, {authApi} from 'utils/axios';

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    manager: null,
    temp: false
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
    if (accessToken) {
        if (!accessToken.startsWith('Bearer'))
            accessToken = `Bearer ${accessToken}`;
        localStorage.setItem('authorization', accessToken);
        axios.defaults.headers.common.Authorization = `${accessToken}`;
        authApi.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
        localStorage.removeItem('authorization');
        delete axios.defaults.headers.common.Authorization;
        delete authApi.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {isInitialised, isAuthenticated, user, manager, branch, temp} = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised,
                user: user ? new User(user) : null,
                manager: manager ? new Manager(manager) : null,
                branch: branch ? new Branch(branch) : null,
                temp
            };
        }
        case 'LOGIN': {
            const {user, manager, branch, temp} = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user: new User(user),
                manager: manager ? new Manager(manager) : null,
                branch: branch ? new Branch(branch) : null,
                temp
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                manager: null,
                branch: null,
                temp: false
            };
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    login: () => Promise.resolve(),
    logout: () => {},
});


export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const verify = async () => {
        try {
            const authorization = window.localStorage.getItem('authorization');
            if (authorization && isValidToken(authorization)) {
                setSession(authorization);
                const response = await axios.get('/v1/branch/manager/'); // app only calls customer for verify user it self
                const {result} = response.data;
                const {manager, branch, user} = result;
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isInitialised: true,
                        isAuthenticated: !!user,
                        manager,
                        branch,
                        user,
                        temp: user ? user.isTemp : false
                    }
                });
            } else {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isInitialised: true,
                        isAuthenticated: false,
                        manager: null,
                        user: null,
                    }
                });
            }
        } catch (err) {
            const manager = err.response?.data?.result?.manager;
            const user = err.response?.data?.result?.user;
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isInitialised: true,
                    isAuthenticated: !!user,
                    manager,
                    user,
                }
            });
        }
    };

    const login = async (id, password) => {
        const response = await axios.post('/v1/login', {id, password});
        const {accessToken, user} = response.data;
        setSession(accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
        await verify();
    };

    const logout = () => {
        setSession(null);
        dispatch({type: 'LOGOUT'});
    };


    useEffect(() => {
        verify().catch(console.error);
    }, [state?.user?.id]);

    if (!state.isInitialised) {
        return <Load/>;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
