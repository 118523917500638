import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinkHomeLogo from 'components/LinkHomeLogo';
import Link from 'components/Link';

import useAuth from 'hooks/useAuth';


const useStyles = makeStyles(theme => ({
    bar: {
        zIndex: theme.zIndex.bar,
    },
    logo: {
        height: 28
    },
    toolBar: {
        justifyContent: 'space-between'
    },
    user: {
        textDecoration: 'underline'
    },
    menus: {
        marginLeft: theme.spacing(1)
    }
}));

const TopBar = ({className}) => {
    const classes = useStyles();
    const {user, logout} = useAuth();
    return (
        <AppBar
            className={clsx(classes.bar, className)}
            color={'inherit'}
            position={'sticky'}
            elevation={0}
        >
            <Toolbar className={classes.toolBar}>
                <LinkHomeLogo
                    className={classes.logo}
                />

                <Button
                    className={classes.user}
                    onClick={logout}
                >
                    로그아웃
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
