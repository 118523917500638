import axios from 'axios';

const baseURL = process.env.NODE_ENV.toUpperCase() === 'PRODUCTION'
    ? 'https://api.bgood.co.kr'
    : 'http://localhost:4000';

const instance = axios.create({
    baseURL,
    timeout: 5000,
    headers: {'Access-Control-Allow-Origin': '*'}
});

export const authApi = axios.create({
    baseURL: 'https://auth.snecompany.com',
    timeout: 5000,
    headers: { 'Access-Control-Allow-Origin': '*' }
})

export default instance;
