import axios from 'utils/axios';
import {useEffect, useState, useCallback, useRef} from 'react';

class Model {
    //onNewData
    static get = async (url, options) => {
        return axios.get(url, options);
    };
    static useGet = (url, options, deps = []) => {
        const [callTime, setCallTime] = useState(1);
        const [response, setResp] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        useEffect(() => {
            setLoading(true);
            setError(null);
            setResp(null);
            this.get(url, options)
                .then((response) => {
                    setResp(response);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [url, callTime, ...deps]);
        const reload = () => {
            setCallTime(reload + 1);
        };
        return {data: response?.data ?? null, error, loading, response, reload};
    };

    static usePagination = (baseUrl, page = 0, limit = 20, query, options, deps = []) => {
        const pageRef = useRef();
        const requestRefs = useRef([]);
        const [callTime, setCallTime] = useState(1);
        const [response, setResp] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        const [requestResultTuples, setRequestResultTuples] = useState([]);
        const [total, setTotal] = useState(0);
        const list = requestResultTuples.reduce((accum, tuple) => [...accum, ...tuple[1]], []);
        useEffect(() => {
            pageRef.current = page;
            setRequestResultTuples([]);
            setLoading(true);
            setError(null);
            setResp(null);
            const url = `${baseUrl}?offset=${pageRef.current * limit}&limit=${limit}${query ? '&' + query : ''}`;
            this.get(url, options)
                .then((response) => {
                    setResp(response);
                    setTotal(+response.data.result.total);
                    setRequestResultTuples([
                        [url, response.data.result.list]
                    ]);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [baseUrl, query, callTime, ...deps]);

        const reload = () => {
            pageRef.current = page;
            setCallTime(reload + 1);
        };


        const next = () => {
            if (loading || list.length >= total || ((pageRef.current + 1) * limit) > total) {
                return;
            }
            const nextPage = pageRef.current + 1;
            setLoading(true);
            const url = `${baseUrl}?offset=${nextPage * limit}&limit=${limit}${query ? '&' + query : ''}`;
            if (requestRefs.current.includes(url) || requestResultTuples.find(([requested]) => url === requested))
                return setLoading(true);
            requestRefs.current.push(url);
            this.get(url, options)
                .then((response) => {
                    setResp(response);
                    setRequestResultTuples([
                        ...requestResultTuples,
                        [url, response.data.result.list]
                    ]);
                    pageRef.current++;
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                    const requestIdx = requestRefs.current.indexOf(url);
                    if (requestIdx >= 0)
                        requestRefs.current.splice(requestIdx, 1,);
                });
        };


        return {list, error, loading, response, reload, next};
    };

    static post = async (url, data, options) => {
        return axios.post(url, data, options);
    };

    static usePost = (url, data, options, deps = []) => {
        const [callTime, setCallTime] = useState(1);
        const [response, setResp] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        useEffect(() => {
            setLoading(true);
            setError(null);
            setResp(null);
            this.post(url, data, options)
                .then((response) => {
                    setResp(response);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [url, callTime, ...deps]);
        const reload = () => {
            setCallTime(reload + 1);
        };
        return {data: response?.data ?? null, error, loading, response, reload};
    };

    static put = async (url, data, options) => {
        return axios.put(url, data, options);
    };

    static usePut = (url, data, options, deps = []) => {
        const [callTime, setCallTime] = useState(1);
        const [response, setResp] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        useEffect(() => {
            setLoading(true);
            this.put(url, data, options)
                .then((response) => {
                    setResp(response);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [url, callTime, ...deps]);
        const reload = () => {
            setCallTime(reload + 1);
        };
        return {data: response?.data ?? null, error, loading, response, reload};
    };

    static delete = async (url, options) => {
        return axios.delete(url, options);
    };

    static useDelete = (url, options, deps = []) => {
        const [callTime, setCallTime] = useState(1);
        const [response, setResp] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        useEffect(() => {
            setLoading(true);
            this.delete(url, options)
                .then((response) => {
                    setResp(response);
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [url, callTime, ...deps]);
        const reload = () => {
            setCallTime(reload + 1);
        };
        return {data: response?.data ?? null, error, loading, response, reload};
    };


    constructor({id}) {
        this.id = id;
    }
}

export default Model;
