import React, { lazy } from 'react';
import MainLayout from 'layouts/MainLayout';
import CloseLayout from 'layouts/CloseLayout';
import PlainLayout from 'layouts/PlainLayout';

export default [
  {
    title: 'B·good - 가맹점 관리자',
    path: '/login',
    exact: true,
    guard: false,
    layout: PlainLayout,
    page: lazy(() => import('../pages/login'))
  },

  {
    title: 'B·good',
    path: '/',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/main')),
  },

  {
    title: 'B·good',
    path: '/customer',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/customers')),
  },

  {
    title: 'B·good',
    path: '/order',
    exact: true,
    layout: MainLayout,
    page: lazy(() => import('../pages/orders')),
  },
];
