import Model from './lib/Model';

class Branch extends Model {
    constructor(raw) {
        super(raw);
        this.name = raw.name;
        this.paymentAccountId = raw.paymentAccountId;
        this.managerUuid = raw.managerUuid;
        this.count = raw.count ?? 0;
    }
}

export default Branch;
